@charset "UTF-8";

@import "./variables";
@import "./functions";

//---------------------------------------------------------------
// font import
//---------------------------------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


//---------------------------------------------------------------
// 基幹設定
//---------------------------------------------------------------

.l-renewal-content {

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: $text-base;
    font-size: $font-size-default;
    font-family: $font-family_1 !important;
    -webkit-font-smoothing: antialiased; // font boldが太くなる対策
  }

  img {
    width: 100%;
  }


  a {
    text-decoration: none;
    color: $text-link;
    transition: all 200ms;

    & > img {
      width: 100%;
    }

    &.underline {
      text-decoration: underline;
    }

    &.color {
      color: $text-primary;
    }

    &:hover {
      opacity: 0.8;

      &:not(.color-disable) {
        color: $text-primary;
      }

      img {
        opacity: 0.8;
      }

      cursor: pointer;
    }
  }

  table {
    border-collapse: collapse;
  }

  form {
    margin-top: 20px;

    label {
      @media (max-width: $media-small) {
        font-size: 14px;
      }
      @media (min-width: $media-small) {
        font-size: 16px;
      }

      &.label {
        font-weight: $font-weight-bold;
      }

      &.required {
        &:after {
          content: "必須";
          margin-left: 12px;
          color: $text-white;
          background-color: $bg-require;
          display: inline-block;
          width: 40px;
          height: 19px;
          text-align: center;
          border-radius: 5px;
          font-size: 12px;
        }
      }

      &.premium {
        &:after {
          content: "PREMIUM";
          font-size: 10px;
          font-weight: $font-weight-bold;
          text-align: center;

          color: $text-white;
          background-color: $bg-premium;

          vertical-align: middle;
          padding-top: 4px;

          display: inline-block;
          margin-left: 8px;
          width: 67px;
          height: 15px;
          border-radius: 5px;
        }
      }
    }

    input[type=text],
    input[type=password],
    input[type=number],
    select {
      padding: 12px;
      border-radius: 10px;
      border: 1px solid #ccc;
      margin-bottom: 8px;
      margin-top: 8px;
      height: 45px;

      &:focus {
        outline: none;
        border: 1px solid $text-primary;
      }
      @media (max-width: $media-small) {
        width: 100%;
      }
    }

    select.sm {
      padding: 8px 10px;
      border-radius: 5px;
      height: auto;
    }

    textarea {
      resize: vertical;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-bottom: 8px;
      margin-top: 8px;
      width: 100%;
      padding: 5px;
      min-height: $font-size-default * 10;
      &:focus {
        outline: none;
        border: 1px solid $text-primary;
      }
    }
  }

  select {
    background: white getIconUrl("icon_select_arrow.svg") no-repeat right 12px center;
    -webkit-appearance: none;
    appearance: none;
    background-size: 10px auto;
  }

  input[type=button]:not(:disabled):hover,
  button:not(:disabled):hover {
    cursor: pointer;
  }

  input[type='radio'] {
    accent-color: $text-base;
  }


  //---------------------------------------------------------------
  // tailwind 上書き必要分
  //---------------------------------------------------------------
  img {
    display: inline-block;
  }

  ////---------------------------------------------------------------
  //// 基幹設定
  ////---------------------------------------------------------------
  //
  //
  //
  //body{
  //  background-color: $base-color-bg-contents;
  //  font-family: $font-family_1 !important;
  //  font-size: 14px;
  //  letter-spacing: 0.03em;
  //  position: relative;
  //  overflow-x: hidden;
  //}
  //

  ol, ul, menu {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    list-style-type: none;
  }
} // .l-renewal-content


body.l-renewal-content {
  margin: 0;
}