@charset "UTF-8";

@import "./variables";

/**
  アイコンのURL生成用
  Todo: 後でJSで関数化する(sassではattrと文字列の結合が不可能なため)
 */
@function getIconUrl($filename) {
  @return url("/assets/ozaking/images/icons/" + $filename);
}