@charset "UTF-8";

  //---------------------------------------------------------------
  // Font →どっちを使用するか確認
  //---------------------------------------------------------------
  //$base-font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI",
  //"Helvetica Neue", HelveticaNeue, "游ゴシック体", YuGothic, "游ゴシック Medium",
  //"Yu Gothic Medium", "游ゴシック", "Yu Gothic", Verdana, "メイリオ", Meiryo,
  //sans-serif;
  $font-family_1: "Inter", "Noto Sans JP", sans-serif;

  $font-size-default: 14px;
  $font-size-xss: 8px;
  $font-size-xs: 10px;
  $font-size-xs-plus1: 10px;
  $font-size-s: 12px;
  $font-size-m: 13px;
  $font-size-m-plus1: 14px;
  $font-size-l: 16px;
  $font-size-xl: 18px;
  $font-size-xxl: 20px;
  $font-size-xxl-plus1: 21px;
  $font-size-xxx: 24px;

  $font-weight-default: 400;
  $font-weight-bold: 700;

  $line-height-normal: 150%;
  $line-height-double: 200%;

  //---------------------------------------------------------------
  // 背景色関連
  //---------------------------------------------------------------

  $bg-primary: #f8a04e;

  $bg-base-gray: #f8f8f8; // main～footerの全体的な背景色
  //$bg-base-white: #fdfdfd; // main内でのセクションに適用する背景色
  $bg-base-white: #fff; // main内でのセクションに適用する背景色
  $bg-base-header: #fff; // header用の背景色
  $bg-base-footer: #fff; // header用の背景色

  $bg-userinfo: #FFFBF0; // サイドメニューのユーザー情報用の背景色

  $bg-disabled: #999;
  $bg-icon: #FEF4EB;
  $bg-emp: #fdf6ef;
  $bg-button: #fff;
  $bg-button-disabled: #bbb;
  $bg-require: #cc0000;
  $bg-premium: #FAA14F;
  $bg-trouble: #FFFBF0;

  $bg-step1: #FFF8F5; // 薄ピンク
  $bg-step2: #F5FAFF; // 薄青
  $bg-step3: #FFFEF5; // 薄黄

  $bg-title: #fdf6ef; // 薄黄
  $bg-diamond: #e3f1fd; // ミッションダイヤ関連用
  $bg-diamond-disabled: #ebebeb; // ミッションダイヤ関連用

  $bg-sns-tiktok: #E5F8FA;
  $bg-sns-instagram: #FAF0FC;
  $bg-sns-x: #EDF8FF;
  $bg-sns-LINE: #EDFFF4;
  $bg-sns-Ameba: #E1FAE3;
  $bg-sns-disconnect: #eee;

  $bg-gray: #f6f6f6; // グレー
  $bg-gray1: #eee; // グレー
  $bg-gray2: #ddd; // グレー
  $bg-gray3: rgba(170, 170, 170, 0.90); // グレー(応募済みラベル)
  $bg-gray-blue: #97AEBE; // 商品
  $bg-gray-green: #C3C9A7; // ポイント
  $bg-gray-pink: #da9f9c; // 商品+ポイント
  $bg-rose-pink: #E5A4C3; // wChance
  $bg-bright-orange: #fdc001; // 明るいオレンジ
  $bg-black: #4B4B4B; // 黒
  $bg-sub-light: #FAF3EF; // 薄オレンジ
  $bg-sub-light2: #FFF8F1; // 薄黄色
  $bg-best-post: #FDF1E1;
  $bg-best-post-past: #FFFBF0;

  $bg-error: #fdf3f0; // エラー用の色

  //$base-color-bg-beige: #f2eee3;        // 質問などで使用する背景色
  //$base-color-bg-lightorange: #fdf6ef;  // 質問などで使用する背景色
  //$base-color-bg-grayshpink: #da9f9c;   // グレイッシュピンク
  //
  //$hover-color-bg-gray: #d7d7d7;    // hover時の色


  //---------------------------------------------------------------
  // 文字色関連
  //---------------------------------------------------------------

  $text-primary: #FAA14F;

  $text-base: #555; // 基本の文字色
  $text-link: #555; // リンクの文字色
  $text-header: #222; // ヘッダーの文字色
  $text-diamond: #78B7ED; // ミッションダイヤ関連用
  $text-diamond-disabled: #A6A6A6; // 薄文字用
  $text-cautionary-note: #888; // 注意書き用
  $text-attention-note: #FAA14F;
  $text-disabled: #999;

  $text-point-plus: #68A0FE;
  $text-point-minus: #FF8080;

  $text-white: #fff; // 薄文字用
  $text-gray: #555; // 薄文字用
  $text-gray-accordion: #bbb; // 薄文字用
  $text-light-gray: #ccc; // 薄文字用

  $text-emp: #f00; // 強調用の赤文字

  $text-error: #d86565; // エラー時の文字色

  //---------------------------------------------------------------
  // border関連
  //---------------------------------------------------------------

  $border-color-base: #f0f0f0;
  $border-color-gray: #eee;
  $border-color-gray2: #E4E4E4;
  $border-color-primary: #f8e5d2;
  $border-color-primary-bold: #f8a04e;
  $border-color-dark-gray: #ddd;

  $border-set-base: 1px solid $border-color-base;
  $border-set-gray: 1px solid $border-color-gray;
  $border-set-dark-gray: 1px solid $border-color-dark-gray;
  $border-set-primary: 1px solid $border-color-primary;
  $border-set-primary-2p: 2px solid $border-color-primary;
  $border-set-primary-3p: 3px solid $border-color-primary;
  $border-set-primary-6p: 6px solid $border-color-primary;

  $border-radius-small: 5px;
  $border-radius-medium: 10px;
  $border-radius-large: 27px;
  $border-radius-round: 50px;
  $border-radius-card: 18px;

  $shadow-default: 0px 4px 4px 0px rgba(85, 85, 85, 0.25);
  $shadow-thin: 0px 4px 4px 0px rgba(85, 85, 85, 0.05);

//---------------------------------------------------------------
  // スクリーンサイズ関連
  //---------------------------------------------------------------
  $media-small: 768px;


  //---------------------------------------------------------------
  // アイコン関連
  //---------------------------------------------------------------
  $icon-setup-center: center center no-repeat;

.l-renewal-content {

  //---------------------------------------------------------
  // アニメーション関連
  //---------------------------------------------------------------
  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes accordion-y {
    from {
      height: 1px;
    }

    to {
      height: auto;
    }
  }

} // .l-renewal-content